<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-body">
            <div class="text-right mb-3">
            <!-- <router-link class="btn btn-primary" to="/rekonsiliasi"> Daftar Rekonsiliasi</router-link> -->
            <button class="btn btn-secondary ml-3" @click.prevent="downloadTemplate"><i class="fa fa-download"></i> Unduh Template <span v-if="loadingDownload" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></button>
            </div>
            <div class="row">
                <div class="col-12">
                <span class="callout callout-warning float-right col-12"><h6><strong>Catatan:</strong> Mohon untuk menggunakan format excel dengan kolom judul yang sesuai dengan template.</h6></span>
                </div>
            </div>
            <form action="/target" ref="dzone" class="dropzone card" style="border-style: dashed;">
            <div class="overlay" v-if="loading">
            <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
            </div>
            </div>
            <div class="dz-message text-muted" data-dz-message><h4><div><i class="fa fa-upload"></i></div><span>Upload file Anda disini</span></h4></div>
            </form>
            </div>
        </div>
        <form role="form" @submit.prevent="submitForm">
        <input id="ujian_id" class="form-control" v-model="form.ujian_id" type="hidden" name="ujian_id" />
        <div class="card">
        <div class="card-header">
            <h3>PREVIEW</h3>
        </div>
        <div class="card-body">
            <div class="row">
            </div>
            <div class="table-responsive">
                <table class="table table-hover" id="tblsoal" ref="tblsoal">
                <thead>
                    <tr>
                    <th>NO</th>
                    <th>SOAL</th>
                    <th>OPSI 1</th>
                    <th>OPSI 2</th>
                    <th>OPSI 3</th>
                    <th>OPSI 4</th>
                    <th>OPSI 5</th>
                    <th>KUNCI JAWABAN</th>
                    <th>PEMBAHASAN</th>
                    <th>POINT</th>
                    </tr>
                </thead>
                <tbody></tbody>
                </table>
            </div>
        </div>
        <div class="card-footer">
            <button type="submit" id="submit" class="btn btn-primary">
                Simpan
            </button>
        </div>
        </div>
        </form>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import Dropzone from "dropzone";
import XLSX from "xlsx";
import Swal from 'sweetalert2';
import $ from 'jquery';
import "dropzone/dist/dropzone.css";
import datepicker from "@/components/Datepicker";
import moment from "moment";

export default {
    name: 'ImportSoal',
    data() {
        return {
            errors: [],
            roles: '',
            loading: false,
            loadingDownload: false,
            data: [],
            formTitle: 'Tambah User',
            form: {
                // tanggal: moment().format("DD/MM/YYYY"),
                ujian_id : '',
            }
        }
    },
    components: {
        datepicker,
    },
    computed: {},
    // watch:{
    //     $route (to, from){
    //     if(to.path === "/soal/import")
    //     {
    //         sessionStorage.filterData='';
    //         sessionStorage.filterStatus = '';
    //     }
    //     }
    // },
    created: function() {
        if (this.$route.params.ujian_id) {
            this.form.ujian_id = this.$route.params.ujian_id;
        }

        if(this.form.ujian_id.length<1)
        {
        if(sessionStorage.ujian_id)this.form.ujian_id = sessionStorage.ujian_id;
        }
        else{
        sessionStorage.ujian_id = this.form.ujian_id;
        sessionStorage.filterStatus = 1;
        }

        console.log("id ujian: ",this.form.ujian_id);
    },
    methods: {
        btnFile() {
            var input = document.querySelector("#excelFile");
            input.click();
        },
        excelImport(file) {
        var table = $('#tblsoal').DataTable();
        table.clear().draw();
        const e = this.$refs;
        var input = file;
        var reader = new FileReader();
        reader.onload = () => {
            // this.tbl.clear().draw();
            var fileData = reader.result;
            var wb = XLSX.read(fileData, { type: "binary" });
            var worksheet = wb.Sheets[wb.SheetNames[0]];
            var rowObj = XLSX.utils.sheet_to_json(worksheet);
            let data = rowObj;
            console.log(data);

            if (
            !data[0].NO ||
            !data[0].SOAL ||
            !data[0].OPSI_1 ||
            !data[0].IS_ANSWER ||
            !data[0].PEMBAHASAN
            ) {
            Swal.fire("Proses gagal", `data atau kolom tidak sesuai!`, "error");
            this.loading = false;
            } else {
                var allData = { group: data };
                var urlSubmit = "/akademik/ujian/upload";

                authFetch(urlSubmit, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(allData),
                }).then((res) => {
                if (res.status === 201) {
                } else if (res.status === 400) {
                }
                return res.json();
                })
                .then((js) => {
                this.loading = false;
                var data = js.data;
                for (var i = 0; i < data.length; i++) {
                    let nRow = this.tbl.fnAddRow(data);
                    var aData = {
                        no: data[i].no,
                        soal: data[i].soal,
                        opsi_1: data[i].opsi_1,
                        opsi_2: data[i].opsi_2,
                        opsi_3: data[i].opsi_3,
                        opsi_4: data[i].opsi_4,
                        opsi_5: data[i].opsi_5,
                        is_answer: data[i].is_answer,
                        pembahasan: data[i].pembahasan,
                        criteria : data[i].criteria,
                        point : data[i].point,
                        point_a : data[i].point_a,
                        point_b : data[i].point_b,
                        point_c : data[i].point_c,
                        point_d : data[i].point_d,
                        point_e : data[i].point_e,
                    };
                    this.tbl.fnUpdate(aData, nRow);
                    $("td .btn.edit", nRow).click();
                }
                });
            }
        };
        reader.readAsBinaryString(input);
        },
        downloadTemplate: function()
        {
            this.loadingDownload = true;
            authFetch("/akademik/ujian/template", {
                method: 'GET',
                headers: {
                'Content-Type': null,
                }
            })
            .then(response => response.blob())
            .then(blob => {
                this.loadingDownload = false;
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = "template.xlsx";
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();    
                a.remove();  //afterwards we remove the element again         
            });

        },
        submitForm: function (ev) {
        var self = this;
        const e = this.$refs;

        // moment(self.form.tanggal).format("YYYY-MM-DD");
        
        var data = this.tbl.getSerialize();
        var items = [];
        this.tbl
        .api()
        .rows()
        .every(function(rowIdx, tableLoop, rowLoop) {
        var d = this.data();
        items.push(d);
        });

        var allData = { group: self.form, items: items };
        var urlSubmit = "/akademik/ujian/import_soal";

        Swal.fire({
        title: "Simpan?",
        showCancelButton: true,
        confirmButtonText: `Ya`,
        cancelButtonText: "Tidak",
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
        authFetch(urlSubmit, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(allData),
        })
        .then((res) => {
            return res.json();
        })
        .then((js) => {
            if (js.success) {
                Swal.fire("Proses Berhasil", ``, "success");
                // this.form = {};
                this.tbl.api().ajax.reload();
                this.$router.push("/detail-ujian/" + this.form.ujian_id);
            } else {
                Swal.fire("Proses gagal", ``, "error");
            }
        });
        }
        });

        ev.preventDefault();
        },
    },
    mounted() {
        const e = this.$refs;
        const self = this;

        //upload excel
        Dropzone.autoDiscover = false;
        let myDropzone = new Dropzone(this.$refs.dzone, {
            uploadMultiple: false, maxFiles: 1, maxFilesize: 20,
            acceptedFiles: ".xls,.xlsx", createImageThumbnails: false,
            autoProcessQueue: false,
            url: 'getMeSomeUrl',
            accept: function(file, done) {
                console.log('accept', file, done)
                this.removeFile(file);
            },
            success: function(file)
            {
                this.removeFile(file);
            }
        });
        myDropzone.on("addedfile", file => {
        console.log(`File added: ${file.name}`);
        console.log(file)
        this.loading = true;
        var input = file;
        this.excelImport(input);
        });
        myDropzone.on("maxfilesexceeded", function (file) {
            myDropzone.removeAllFiles();
        });

        this.tbl = createTable(e.tblsoal, {
        title: "",
        serverSide: false,
        ajax: "/akademik/ujian/banksoal",
        // paramData: function (d) {},
        selectedRowClass: "",
        frame: false,
        displayLength: 100,
        lengthChange: false,
        filter: false,
        columnDefs: [
            {
                targets: [1,2,3,4,5,6,7,8],
                className: 'text-wrap'
            }
        ],
        columns: [
            { data: "no" },
            { data: "soal" },
            { data: "opsi_1" },
            { data: "opsi_2" },
            { data: "opsi_3" },
            { data: "opsi_4" },
            { data: "opsi_5" },
            { data: "is_answer" },
            { data: "pembahasan" },
            { data: "point" }
        ],
        rowCallback: function (row, data) {}
        });
    }
}
</script>
<style scoped>
.drag-area {
  border: 2px dashed rgb(114, 114, 114);
  min-height: 250px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.drag-area.active {
  border: 2px solid rgb(133, 133, 133);
}

.drag-area .icon {
  font-size: 100px;
  color: rgb(139, 139, 139);
}

.drag-area header {
  font-size: 30px;
  font-weight: 500;
  color: rgb(126, 126, 126);
}

.drag-area span {
  font-size: 25px;
  font-weight: 500;
  color: rgb(129, 129, 129);
  margin: 10px 0 10px 0;
}

.drag-area button {
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  background: rgb(206, 206, 206);
  color: #5256ad;
  border-radius: 5px;
  cursor: pointer;
}

.drag-area img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
</style>