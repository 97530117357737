var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"content"},[_c('div',{staticClass:"card card-primary"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-right mb-3"},[_c('button',{staticClass:"btn btn-secondary ml-3",on:{"click":function($event){$event.preventDefault();return _vm.downloadTemplate.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-download"}),_vm._v(" Unduh Template "),(_vm.loadingDownload)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()])]),_vm._m(0),_c('form',{ref:"dzone",staticClass:"dropzone card",staticStyle:{"border-style":"dashed"},attrs:{"action":"/target"}},[(_vm.loading)?_c('div',{staticClass:"overlay"},[_vm._m(1)]):_vm._e(),_vm._m(2)])])]),_c('form',{attrs:{"role":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.ujian_id),expression:"form.ujian_id"}],staticClass:"form-control",attrs:{"id":"ujian_id","type":"hidden","name":"ujian_id"},domProps:{"value":(_vm.form.ujian_id)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "ujian_id", $event.target.value)}}}),_c('div',{staticClass:"card"},[_vm._m(3),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"}),_c('div',{staticClass:"table-responsive"},[_c('table',{ref:"tblsoal",staticClass:"table table-hover",attrs:{"id":"tblsoal"}},[_vm._m(4),_c('tbody')])])]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('span',{staticClass:"callout callout-warning float-right col-12"},[_c('h6',[_c('strong',[_vm._v("Catatan:")]),_vm._v(" Mohon untuk menggunakan format excel dengan kolom judul yang sesuai dengan template.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dz-message text-muted",attrs:{"data-dz-message":""}},[_c('h4',[_c('div',[_c('i',{staticClass:"fa fa-upload"})]),_c('span',[_vm._v("Upload file Anda disini")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-header"},[_c('h3',[_vm._v("PREVIEW")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("NO")]),_c('th',[_vm._v("SOAL")]),_c('th',[_vm._v("OPSI 1")]),_c('th',[_vm._v("OPSI 2")]),_c('th',[_vm._v("OPSI 3")]),_c('th',[_vm._v("OPSI 4")]),_c('th',[_vm._v("OPSI 5")]),_c('th',[_vm._v("KUNCI JAWABAN")]),_c('th',[_vm._v("PEMBAHASAN")]),_c('th',[_vm._v("POINT")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-footer"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","id":"submit"}},[_vm._v(" Simpan ")])])
}]

export { render, staticRenderFns }